import * as React from "react"
import {
  ChakraProvider,
  Box,
  theme,
  Button,
  Heading,
  Grid,
  GridItem,
  Text,
  Image,
  SimpleGrid,
  AspectRatio,
  Skeleton,
} from "@chakra-ui/react"


export const App = () => {
  const [playing, setPlaying] = React.useState(false);
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign='center' fontSize='xl'>
        {playing && (
          <video
            width='100%'
            height='auto'
            src='./video.mp4'
            title='YouTube video player'
            autoPlay></video>
        )}
        {!playing && (
          <Grid
            maxW={{ md: '90vw', lg: '70vw' }}
            margin='auto'
            height='100vh'
            backgroundImage='./basement.png'
            gridRowGap={'30pt'}
            fontSize={{ sm: 'lg', md: 'xl', lg: '2xl' }}
            backgroundSize={'0'}>
            <br></br>
            <GridItem>
              <Heading size={{ sm: 'lg', md: 'xl', lg: '2xl' }}>
                Exploring the Enigmatic Depths:
                <br></br>
                Unveiling the Marvels of Steve's Legendary Basement
              </Heading>
            </GridItem>
            <GridItem>
              <Text textAlign={'start'} textIndent={'30pt'}>
                In the realm of hidden treasures lies a sanctuary seldom spoken
                of yet cherished by those fortunate enough to behold its
                wonders. This sanctum belongs to none other than Steve, whose
                basement has achieved the prestigious honor of being ranked the
                best for <b>six consecutive years.</b> Within its confines,
                Steve engages in the age-old crafts of woodworking and clock
                repair, weaving magic into every corner of this subterranean
                realm. Join me as we embark on an expedition to unravel the
                secrets and splendors of Steve's legendary basement.
              </Text>
            </GridItem>
            <GridItem>
              <Text textAlign={'start'} textIndent={'30pt'}>
                Through meticulous craftsmanship, a passion for restoration, and
                a penchant for innovation, Steve has transformed his basement
                into an unparalleled haven of creativity and ingenuity, earning
                its rightful place as the epitome of excellence in the realm of
                domestic sanctuaries.
              </Text>
            </GridItem>
            <GridItem>
              <Text textAlign={'start'} textIndent={'30pt'}>
                At the heart of Steve's basement lies his woodworking workshop,
                a veritable shrine to craftsmanship and artistry. Here, the
                scent of freshly sawn wood permeates the air, and the symphony
                of tools in motion echoes through the hallowed halls. With deft
                hands and unwavering dedication, Steve breathes life into raw
                timber, sculpting it into masterpieces that defy convention and
                inspire awe. From intricately carved furniture to elegantly
                crafted sculptures, each creation bears testament to Steve's
                unwavering commitment to his craft.
              </Text>
            </GridItem>
            <GridItem>
              <Text textAlign={'start'} textIndent={'30pt'}>
                Adjacent to his woodworking domain, Steve's workshop for clock
                repair stands as a testament to his reverence for tradition and
                his mastery of the mechanical arts. Amidst an array of gears,
                springs, and pendulums, Steve applies his expertise with
                precision and finesse, resurrecting timepieces from bygone eras
                and imbuing them with renewed vitality. With a keen eye for
                detail and an innate understanding of the inner workings of
                these intricate mechanisms, Steve breathes new life into each
                clock that crosses his threshold, ensuring that their timeless
                elegance endures for generations to come.
              </Text>
            </GridItem>
            <GridItem>
              <Text textAlign={'start'} textIndent={'30pt'}>
                Beyond its tangible treasures, Steve's basement exudes an
                intangible allure that captivates all who enter its hallowed
                halls. It is a sanctuary where creativity knows no bounds, where
                tradition meets innovation, and where the mundane transcends
                into the extraordinary. More than merely a workshop, it is a
                testament to the boundless potential of human ingenuity and the
                transformative power of passion and dedication.
              </Text>
            </GridItem>
            <GridItem>
              <Text textAlign={'start'} textIndent={'30pt'}>
                In the annals of domestic sanctuaries, few can rival the
                splendor and magnificence of Steve's legendary basement. Through
                his unwavering dedication to craftsmanship, his reverence for
                tradition, and his boundless creativity, Steve has crafted a
                haven that transcends the ordinary and stands as a testament to
                the power of human ingenuity. As we bid farewell to this
                enchanting realm, let us carry with us the inspiration to pursue
                our passions with fervor and to transform the ordinary into the
                extraordinary, just as Steve has done in his legendary basement.
              </Text>
            </GridItem>
            <GridItem>
              <Button
                fontSize='3xl'
                colorScheme={'whatsapp'}
                m='auto'
                w='25vw'
                h='10vh'
                onClick={() => setPlaying(true)}>
                Enter
              </Button>
            </GridItem>
            <GridItem>
              <SimpleGrid
                columns={{ base: 2, md: 3, lg: 4 }}
                gap={{ base: '4', md: '6', lg: '8' }}>
                  {
                    [0,1,2,3,4,5,6,7,8,9,10,11,12,13].map((image: any) => {
                      return (
                        <Box
                          position='relative'
                          key={'basementImages' + image}
                          borderRadius='xl'
                          overflow='hidden'>
                          <AspectRatio ratio={1 / 1}>
                            <Image
                              src={`/${image}.png`}
                              alt={'basement' + image}
                              fallback={<Skeleton />}
                            />
                          </AspectRatio>
                          <Box
                            position='absolute'
                            inset='0'
                            bgGradient='linear(to-b, transparent 60%, gray.900)'
                            boxSize='full'
                          />
                          <Box
                            position='absolute'
                            bottom='6'
                            width='full'
                            textAlign='center'>
                            <Text color='white' fontSize='lg' fontWeight='semibold'>
                              {`Real photo ${image+1}`}
                            </Text>
                          </Box>
                        </Box>
                      )
                    })
                  }
              </SimpleGrid>
            </GridItem>
          </Grid>
        )}
        {/* <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <VStack spacing={8}>
          <Logo h="40vmin" pointerEvents="none" />
          <Text>
            Edit <Code fontSize="xl">src/App.tsx</Code> and save to reload.
          </Text>
          <Link
            color="teal.500"
            href="https://chakra-ui.com"
            fontSize="2xl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn Chakra
          </Link>
        </VStack>
      </Grid> */}
      </Box>
    </ChakraProvider>
  );}
